import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/public/logo-short.svg");
;
import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/public/logo.svg");
;
import(/* webpackMode: "eager" */ "/var/www/apps/dashboard/public/not-found.png");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/.pnpm/next@14.2.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.63.6/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Title","Button"] */ "/var/www/node_modules/.pnpm/rizzui@0.8.4_@floating-ui+react@0.26.19_react-dom@18.3.1_react@18.3.1__react@18.3.1__@headles_6fwmr4aqnvastrdfpq243gtc4y/node_modules/rizzui/dist/index.mjs");
